<template>
  <div v-once class="single">
    <CImage
      class="single__img"
      src="single.webp"
      fallback="single.jpg"
      alt="Букет из 21 розы Кения"
      @mousemove="onMouseOver"
      @mouseleave="onMouseLeave"
    />
    <button type="button" class="single__btn">
      <CIcon class="single__icon" name="search" />
      Быстрый просмотр
    </button>
  </div>
</template>

<script>
import CIcon from '@/components/common/CIcon'
import CImage from '@/components/common/CImage'

export default {
  name: 'ProductCardSingle',

  components: {
    CIcon,
    CImage,
  },

  methods: {
    onMouseOver(e) {
      const percent = 1.5
      const { right, top, left, bottom } = e.target.getBoundingClientRect()
      const imgWidthCenter = (right - left) / 2
      const imgHeightCenter = (bottom - top) / 2
      const insideImgTop = e.clientY - top - imgHeightCenter
      const insideImgLeft = e.clientX - left - imgWidthCenter
      const parallaxX = (insideImgLeft / imgWidthCenter) * percent * 1.5
      const parallaxY = (insideImgTop / imgHeightCenter) * percent * 1.5
      e.target.style.transform = `scale(${percent}) translate3d(${-parallaxX}rem, ${-parallaxY}rem, 0)`
    },

    onMouseLeave(e) {
      setTimeout(() => {
        e.target.style.transform = `scale(1) translate3d(0, 0, 0)`
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.single {
  position: relative;
  width: 100%;
  max-width: rem(214);
  height: rem(214);
  margin: 0 auto;
  &:hover {
    .single__btn {
      visibility: visible;
      opacity: 1;
    }
  }

  &__img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: rem(2);

    /deep/ .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity $default-transition, visibility $default-transition,
        transform $default-transition;
      will-change: transform;
      &:hover {
        transition: unset;
      }
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: rem(9);
    font-weight: bold;
    font-size: rem(12);
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: rem(2);
    box-shadow: 0 rem(4) rem(9) rgba(36, 31, 33, 0.11);
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    transition: opacity $default-transition, visibility $default-transition;
    &:hover {
      text-decoration: underline;
    }
  }

  &__icon {
    height: rem(12);
    margin: 0 rem(8) 0 0;
    @include base-width(12);
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .single {
    max-width: calc(100% - #{rem(48)});
    height: unset;
    &__img {
      &:before {
        display: block;
        width: 100%;
        padding-top: (1 / 1) * 100%;
        content: '';
      }
    }
  }
}
</style>
