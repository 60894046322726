/* eslint-disable no-param-reassign */

const loadImage = (element, context) => {
  if (element.querySelector('[type="image/webp"]')) {
    element.querySelector('[type="image/webp"]').srcset = element.dataset.src
  }

  element.querySelector('[type="image/all"]').srcset = element.dataset.fallback
  element.querySelector('img').src = element.dataset.fallback
}

const callback = (element, context) => (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      loadImage(element, context)
      observer.unobserve(element)
    }
  })
}

function initIntersectionObserver(element, cb) {
  const options = {
    root: null,
    threshold: 0,
    rootMargin: '0px',
  }

  const observer = new IntersectionObserver(cb, options)
  observer.observe(element)
}

const inserted = (element, binding, vnode) => {
  if (!window.IntersectionObserver) {
    loadImage(element)
  } else {
    initIntersectionObserver(element, callback(element, vnode.context))
  }
}

export default {
  inserted,
}
