<template>
  <!-- TODO: Добавить на @error обработчик -->
  <picture
    v-lazyload
    class="picture"
    :data-src="srcPath"
    :data-fallback="fallbackPath"
  >
    <source v-if="src" type="image/webp" />
    <source type="image/all" />
    <img
      :class="['image', { loaded: isLoaded }]"
      v-bind="$attrs"
      @load="onLoaded"
      v-on="$listeners"
    />
    <CLoader v-if="!isLoaded && !disableLoader" />
  </picture>
</template>

<script>
import lazyload from '@/directives/lazyload'
import CLoader from '@/components/common/CLoader'

export default {
  name: 'CustomImage',

  components: {
    CLoader,
  },

  directives: {
    lazyload,
  },

  inheritAttrs: true,

  props: {
    src: {
      type: String,
    },
    fallback: {
      type: String,
      required: true,
    },
    isExternal: Boolean,
    disableLoader: Boolean,
  },

  data() {
    return {
      isLoaded: false,
    }
  },

  computed: {
    srcPath() {
      return this.src ? require(`@/assets/images/${this.src}`) : ''
    },
    fallbackPath() {
      return this.isExternal
        ? this.fallback
        : require(`@/assets/images/${this.fallback}`)
    },
  },

  methods: {
    onLoaded() {
      this.isLoaded = true
      this.$emit('loaded', this.isLoaded)
    },
  },
}
</script>

<style lang="scss" scoped>
.image {
  max-width: 100%;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity $default-transition, visibility $default-transition;
  pointer-events: none;
  &.loaded {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}

.picture {
  position: relative;
}
</style>
